@tailwind base;
@tailwind components;
@tailwind utilities;

.menu-card {
	@apply flex flex-col justify-center items-center bg-white h-screen py-40;
}

.main-heading {
	@apply md:text-5xl sm:text-5xl text-3xl  mb-12 text-yellow-500 text-center;
}

.center-content {
	@apply flex flex-col justify-center items-center;
}

.sub-heading {
	@apply text-2xl mb-2;
}
