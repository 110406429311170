@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");

* {
	font-family: "Noto Sans JP", sans-serif;
}

*::-webkit-scrollbar {
	width: 0.35rem;
}

*::-webkit-scrollbar-track {
	background-color: transparent;
}

*::-webkit-scrollbar-thumb {
	background-color: #eca239;
}
